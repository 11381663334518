import { FilterStorage } from '@bytel/product-wall/types';

import { WallConfigType } from '@app-types/config';
import { Product } from '@app-types/product';

import { formatTagText, sendTracking } from '@helpers/tracking';

export function sendFilterTracking(
    wallConfig: WallConfigType,
    isRenewal: boolean,
    label: string,
    value: string,
    checked: boolean,
) {
    const filterData = wallConfig.filters.find((filter) => filter.key === label);

    if (filterData === undefined) {
        return;
    }

    let name: string;
    if (filterData.storage !== FilterStorage.QUERY) {
        const valueData = filterData?.values?.find((currentValue) => currentValue.key === value);
        if (valueData === undefined) {
            return;
        }

        name = formatTagText(`${valueData.label}_${checked ? 'coche' : 'decoche'}`);
    } else {
        name = formatTagText(`Prix_max_${value}`);
    }

    const category =
        filterData.label === 'Mode de livraison' ? 'Click_and_collect' : formatTagText('Filtre_' + filterData.label);

    sendTracking(isRenewal, {
        action: 'Clic_selection',
        category,
        name,
    });
}

export function sendResetFilterTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_selection',
        category: 'Filtre',
        name: 'Tout_decocher',
    });
}

export function sendTopFilterTracking(isRenewal: boolean, label: string) {
    const name = (label === 'Click & Collect' ? 'click_and-collect' : formatTagText(label)) + '_mur_telephone';

    sendTracking(isRenewal, {
        action: 'Clic_affichage',
        category: 'Filtre',
        name: formatTagText(name),
    });
}

export function sendSearchTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_selection',
        category: 'Filtre_ZoneRecherche',
        name: 'Icone_ZoneRecherche',
    });
}

export function sendSortTracking(wallConfig: WallConfigType, isRenewal: boolean, sort: string) {
    let sortLabel = wallConfig.sorter[sort]?.label ?? '';
    sortLabel = sortLabel.charAt(0).toUpperCase() + sortLabel.substring(1).toLowerCase();

    sendTracking(isRenewal, {
        action: 'Clic_selection',
        category: 'Tri',
        name: formatTagText(`Trier_par_${sortLabel}`),
    });
}

export function sendPriceDetailTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_affichage',
        category: 'Vignette_telephone',
        name: 'icone_information',
    });
}

export function sendAddToComparatorTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_selection',
        category: 'Vignette_telephone',
        name: 'Ajouter_au_comparateur',
    });
}

export function sendRemoveFromComparatorTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_selection',
        category: 'Vignette_telephone',
        name: 'Supprimer_tel_comparaison',
    });
}

export function sendResetComparatorTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_affichage',
        category: 'Comparaison_telephone',
        name: 'Supprimer_tous_tels_comparaison',
    });
}

export function sendToggleComparatorTracking(isRenewal: boolean, opened: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_affichage',
        category: 'Comparaison_telephone',
        name: opened ? 'Voir_le_comparateur' : 'Masquer_le_comparateur',
    });
}

export function sendRedirectToComparatorTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_affichage',
        category: 'Comparaison_telephone',
        name: 'Lancer_comparaison',
    });
}

export function sendRecommendedProductTracking(isRenewal: boolean, isPremium: boolean, product: Product) {
    const category = isPremium ? 'Recommandation_sensation' : 'Recommandation_sowo';

    const name = formatTagText(`${product.id}_${product.name}`);

    sendTracking(isRenewal, {
        action: 'Impressions',
        category,
        name,
    });
}

export function sendPageChangeTracking(isRenewal: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_redirection',
        category: 'Pagination',
        name: `Changement_de_page`,
    });
}
