import { ProductInterface } from '@bytel/product-wall/types';

import { getSessionVariable, setSessionVariable } from '@services/storage';

export function storeComparator(isRenewal: boolean, comparedProducts: ProductInterface[]) {
    setSessionVariable(
        'catalog_comparator',
        JSON.stringify({
            data: { isRenew: isRenewal, products: comparedProducts },
        }),
    );
}

export function getStoredComparator(): ProductInterface[] {
    const storedComparator = getSessionVariable('catalog_comparator');
    const response = storedComparator ? JSON.parse(storedComparator) : { data: { isRenew: false, products: [] } };
    return response?.data?.products ?? [];
}
