export const TITLE_MATCHER: Record<string, string> = {
    Téléphones: 'Smartphones',
    'Bons Plans': 'en promotion',
    'Click & Collect': 'en Click & Collect',
    Apple: 'iPhone',
    'Smartphone pas cher': 'pas cher',
    'Meilleur rapport qualité-prix': 'au meilleur rapport qualité-prix',
    'Meilleur smartphone': ': notre top selection',
    'Carte SIM': 'avec carte SIM',
    eSIM: 'avec eSIM',
};

export const DESCRIPTION_MATCHER: Record<string, string> = {
    'Meilleur smartphone': 'de notre top selection',
};

export const BASE_KEYWORDS = 'Téléphone, téléphones, smartphone, smartphones, ';
