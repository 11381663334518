import { getWallConfig } from '@services/config';
import { getWallProducts } from '@services/products/index';

import { WallType } from '@app-types/config';
import { PagePropsWithParams } from '@app-types/page';

import { AccessoryImageThumbor } from '@components/image-thumbor';

import { getActiveFilters } from '@helpers/seo';

import { getSearchList } from '@product-wall/actions';
import { Wall } from '@product-wall/components';

const wallConfig = getWallConfig(WallType.ACCESSORY);

export async function AccessoryWall({ searchParams, params }: Partial<PagePropsWithParams<{ filter: string }>>) {
    const page = typeof searchParams?.page === 'string' ? Number(searchParams.page) : 1;
    const sort = typeof searchParams?.sort === 'string' ? searchParams.sort : 'meilleures-ventes';
    const autoComplete = searchParams?.nom;
    const activeFilters = getActiveFilters(wallConfig, params?.filter ?? '', new URLSearchParams(searchParams));

    const productsPromise = getWallProducts(WallType.ACCESSORY, {
        sort,
        pageNumber: page,
        limit: wallConfig.productByPage,
        filters: activeFilters,
        autoComplete,
    });
    const searchListPromise = getSearchList(WallType.ACCESSORY);

    const [{ products, count }, searchList] = await Promise.all([productsPromise, searchListPromise]);

    return (
        <Wall
            title={wallConfig.baseTitle}
            type={WallType.ACCESSORY}
            products={products}
            pageSize={wallConfig.productByPage}
            count={count}
            startPage={page}
            activeSort={sort}
            wallConfig={wallConfig}
            searchParams={searchParams}
            activeFilters={activeFilters}
            searchList={searchList}
            autoComplete={autoComplete}
            handleLogin={false}
            imageAs={AccessoryImageThumbor}
            trackingData={{
                page: 'Mur_accessoires',
                pageCategory: 'Liste_produits_telephone',
                marketLine: 'Mobile',
                isB2B: false,
                eCommerceStep: 'Liste_produits_accessoires',
                variant: { Recommandation: products.some((product) => product.recommended) },
            }}
        />
    );
}
