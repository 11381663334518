'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { ActiveFiltersType } from '@bytel/product-wall';
import { countActiveFilters } from '@bytel/product-wall/helpers';
import {
    BreadcrumbItem,
    Breadcrumb as BreadcrumbTrilogy,
    Container,
    Divider,
    TrilogyColor,
} from '@bytel/trilogy-react-ts';

import { WallConfigType } from '@app-types/config';

import { StructuredData } from '@components/structured-data';

import { aggregateFilters } from '@helpers/seo';
import { getBreadcrumbStructuredData } from '@helpers/structured-data';

type BreadcrumbProps = {
    wallConfig: WallConfigType;
    activeFilters: ActiveFiltersType;
};

type ElementType = {
    name: string;
    label: string;
    href?: string;
};

export function Breadcrumb({ wallConfig, activeFilters }: BreadcrumbProps) {
    const pathname = usePathname();

    const [elements, setElements] = useState<ElementType[]>([]);

    useEffect(() => {
        const defaultElements: ElementType[] = [
            {
                name: 'home',
                label: 'Accueil',
                href: '/',
            },
            {
                name: 'wall',
                label: `${wallConfig.breadcrumb.baseText}${wallConfig.breadcrumb.title}`,
                href: wallConfig.baseUrl,
            },
        ];
        const newElements: ElementType[] = [];

        const activeFiltersCount = countActiveFilters(activeFilters);

        if (
            0 < activeFiltersCount &&
            activeFiltersCount <= 2 &&
            !Object.values(activeFilters).some((values) => values.length > 1)
        ) {
            newElements.push({
                name: 'filter',
                label: `${wallConfig.breadcrumb.baseText} ${aggregateFilters(activeFilters, wallConfig, 'title').join(
                    ' ',
                )}`,
            });
        }
        setElements([...defaultElements, ...newElements]);
    }, [activeFilters]);

    return (
        <>
            <StructuredData data={getBreadcrumbStructuredData(pathname, elements)} />
            <Container fluid background={TrilogyColor.WHITE}>
                <BreadcrumbTrilogy data-cy='breadcrumb'>
                    {elements.map(({ name, href, label }, index) => (
                        <BreadcrumbItem
                            key={name}
                            className={index === elements.length - 1 ? '' : 'is-underline'}
                            href={href}
                            active={index === elements.length - 1}
                        >
                            {label}
                        </BreadcrumbItem>
                    ))}
                </BreadcrumbTrilogy>
            </Container>
            <Divider />
        </>
    );
}
