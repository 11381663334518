'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import {
    Columns,
    ColumnsItem,
    Icon,
    IconName,
    IconPosition,
    IconSize,
    Modal,
    ModalTitle,
    Text,
} from '@bytel/trilogy-react-ts';

export interface RedirectionOptionsType {
    acquisition: { url: string; icon?: IconName; cta?: string };
    renewal: { url?: string; icon?: IconName; cta?: string };
}

interface ProductRedirectModalProps {
    options: RedirectionOptionsType;
    onAction?: (shouldRedirect?: boolean) => void;
}

export function ProductRedirectModal({ options, onAction }: Readonly<ProductRedirectModalProps>) {
    const router = useRouter();

    const onExit = () => {
        onAction?.();
        router.push(options.acquisition.url);
    };

    return (
        <Modal active closeIcon onClose={onExit} className='z-level-10'>
            <ModalTitle>Êtes-vous nouveau ou déjà client ?</ModalTitle>
            <Columns marginless>
                <ColumnsItem>
                    <Icon
                        name={options.acquisition.icon ?? IconName.MOBILE}
                        size={IconSize.LARGE}
                        position={IconPosition.UP}
                        content='Nouveau client'
                        textClassName='is-size-4 has-text-weight-semibold'
                    />
                    <Text className='is-size-6 has-text-weight-light'>&nbsp;</Text>
                    <Link
                        className='button is-primary'
                        onClick={() => onAction?.()}
                        href={options.acquisition.url}
                        data-cy='prospect-client'
                    >
                        {options.acquisition.cta ?? 'Continuer ma visite'}
                    </Link>
                </ColumnsItem>
                <ColumnsItem className='has-background-grey-lighter'>
                    <Icon
                        name={options.renewal.icon ?? IconName.MOBILE_UP_DOWN}
                        size={IconSize.LARGE}
                        position={IconPosition.UP}
                        content='Déjà client'
                        textClassName='is-size-4 has-text-weight-semibold'
                    />
                    <Text className='is-size-6 has-text-weight-light'>Profitez de prix personnalisés !</Text>
                    <Link
                        className='button is-secondary'
                        onClick={() => onAction?.(true)}
                        href={options.renewal.url ?? ''}
                        data-cy='renewal-client'
                    >
                        {options.renewal.cta ?? 'Changer de téléphone'}
                    </Link>
                </ColumnsItem>
            </Columns>
        </Modal>
    );
}
