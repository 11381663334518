import Link from 'next/link';

type LinkCustomProps = {
    href: string;
    children: React.ReactNode;
    replace: boolean;
    scroll: boolean;
    prefetch: boolean;
};

export function LinkCustom({ href, prefetch = false, children, ...props }: LinkCustomProps) {
    return (
        <Link href={href} prefetch={prefetch} {...props}>
            {children}
        </Link>
    );
}
