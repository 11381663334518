import { ActiveFiltersType } from '@bytel/product-wall';
import { countActiveFilters } from '@bytel/product-wall/helpers';
import { FilterStorage, ObjectFilterType } from '@bytel/product-wall/types';

import { WallConfigType } from '@app-types/config';
import { PlanDetailWithProvenanceType } from '@app-types/plan';

import { DESCRIPTION_MATCHER, TITLE_MATCHER } from '@constants/seo';

const titleFilterOrder = [
    'marque',
    'connectivite',
    'etat',
    'systemeExploitation',
    'telephoneAvec',
    'disponibleCnc',
    'prix',
    'typeSim',
];

/** This function returns an url with the filters, filters are sorted by the wallConfig.filtersOrder then by name alphabetically  */
export const generateHref = (
    wallConfig: WallConfigType,
    activeFilters: ActiveFiltersType,
    searchParams: URLSearchParams = new URLSearchParams(),
    keepPage = false,
): string => {
    const filtersName = wallConfig.filtersOrder;

    const selectedFilters: string[] = [];

    // For each filter category, search if present in activeFilters and add to selectedFilters the names sorted alphabetically
    filtersName.forEach((filterName) => {
        let selectedFilter = activeFilters[filterName];
        const filtersObject = wallConfig.filtersObject[filterName] as ObjectFilterType;

        if (selectedFilter) {
            if (filtersObject.storage === FilterStorage.QUERY) {
                searchParams.delete(filterName);
                searchParams.append(filterName, selectedFilter.join(','));
            } else {
                selectedFilter = selectedFilter.map((activeFilter) => {
                    return (
                        Object.values(filtersObject.values ?? {}).find((item) => item.value === activeFilter)?.key ??
                        activeFilter
                    );
                });
                selectedFilter.sort();
                selectedFilters.push(...selectedFilter);
            }
        } else {
            if (filtersObject.storage === FilterStorage.QUERY) {
                searchParams.delete(filterName);
            }
        }
    });

    if (!keepPage) {
        searchParams.delete('page');
    }

    let searchParamsString = '';
    if (searchParams.size > 0) {
        searchParamsString = `?${searchParams.toString()}`;
    }

    return `${selectedFilters.length > 0 ? 'filtre/' : ''}${selectedFilters.join('-')}${searchParamsString}`;
};

/** This function returns the activeFilter corresponding to the url generated by the generateHref function */
export const getActiveFilters = (
    wallConfig: WallConfigType,
    slug: string,
    searchParams?: URLSearchParams,
): ActiveFiltersType => {
    // Retrieve all the possible filters sorted by the order they should appear
    const filterOptions = wallConfig.filtersOrder.reduce((acc: { filterName: string; value: string }[], filterName) => {
        const filtersObject = wallConfig.filtersObject[filterName];

        if (!filtersObject) {
            return acc;
        }

        if (filtersObject.storage === FilterStorage.QUERY) {
            return [...acc, { filterName, value: '' }];
        }

        const options = Object.values(filtersObject.values);
        options.sort((a, b) => a.key.localeCompare(b.key));
        return [...acc, ...options.map(({ key, value }) => ({ filterName, key, value }))];
    }, []);

    // For each possible filter check if he is present in the slug and add it to the activeFilters
    const activeFilters: ActiveFiltersType = {};

    let i = 0;
    while (i < filterOptions.length && slug.length > 0) {
        const { key, filterName, value } = filterOptions[i] as { filterName: string; value: string; key: string };
        if (slug.startsWith(key)) {
            if (!activeFilters[filterName]) {
                activeFilters[filterName] = [];
            }

            (activeFilters[filterName] as string[]).push(value);
            slug = slug.substring(slug.length === key.length ? key.length : key.length + 1);
        }
        i++;
    }

    if (searchParams?.size) {
        filterOptions.forEach(({ filterName }) => {
            if (wallConfig.filtersObject[filterName]?.storage === FilterStorage.QUERY) {
                const filter = searchParams.get(filterName);
                if (filter) {
                    activeFilters[filterName] = filter.split(',');
                }
            }
        });
    }

    return activeFilters;
};

export const addSearchParams = (
    key: string,
    value: string,
    searchParams: URLSearchParams = new URLSearchParams(),
): URLSearchParams => {
    searchParams.delete(key);
    searchParams.append(key, value);
    return searchParams;
};

export const removeSearchParams = (key: string, searchParams: URLSearchParams): URLSearchParams => {
    searchParams.delete(key);
    return searchParams;
};

export const resetSearchParams = (searchParams: URLSearchParams, exceptions?: string[]): URLSearchParams => {
    if (!exceptions) {
        return new URLSearchParams();
    }

    searchParams.forEach((value, key) => {
        if (!exceptions.includes(key)) {
            searchParams.delete(key);
        }
    });
    return searchParams;
};

const useEquivalentTitleIfExist = (text: string): string => {
    return TITLE_MATCHER[text] || text;
};

const useEquivalentDescriptionIfExist = (text: string): string => {
    return DESCRIPTION_MATCHER[text] || TITLE_MATCHER[text] || text;
};

/** This function returns an array of string to use to generate the title or the description of the page  */
export const aggregateFilters = (
    activeFilters: ActiveFiltersType,
    wallConfig: WallConfigType,
    meta: 'title' | 'description' = 'title',
): string[] => {
    const useEquivalentIfExist = meta === 'title' ? useEquivalentTitleIfExist : useEquivalentDescriptionIfExist;

    const activeFiltersArray = Object.entries(activeFilters);
    activeFiltersArray.sort(([a], [b]) => titleFilterOrder.indexOf(a) - titleFilterOrder.indexOf(b));

    return activeFiltersArray.reduce((acc: string[], [filterCategory, filters]) => {
        const labels = filters
            .map((key) => {
                const filtersObject = wallConfig.filtersObject[filterCategory];

                if (!filtersObject?.values) {
                    return '';
                }

                const text = Object.values(filtersObject.values).find((item) => item.value === key)?.label ?? '';

                return useEquivalentIfExist(text);
            })
            .filter((label) => label !== '');

        return [...acc, ...labels];
    }, []);
};

/** This function returns the title to use in the h1 tag  */
export const generateTitle = (
    base: string,
    options?: { activeFilters: ActiveFiltersType; wallConfig: WallConfigType },
): string => {
    if (!options) {
        return base;
    }

    const { wallConfig, activeFilters } = options;

    if (countActiveFilters(activeFilters) > 2) {
        return base;
    }

    // If activeFilter has one category with multiple filters selected => return base
    if (Object.values(activeFilters).some((values) => values.length > 1)) {
        return base;
    }

    // Aggregate selected filters label
    const filtersLabels = aggregateFilters(activeFilters, wallConfig);

    /** Handle exceptions **/
    // If filtered on iPhone do not display base title
    if (['iPhone', 'iPhone en promotion'].includes(filtersLabels[0] || '')) {
        return filtersLabels.join(' ');
    }

    return `${base} ${filtersLabels.join(' ')}`;
};

export const generateSubtitle = (plan: PlanDetailWithProvenanceType): string => {
    if (!plan.name || !plan.obligation) {
        return '';
    }

    return `avec le forfait ${plan.name} ${plan.obligationLabel}`;
};
