import { getAppConfig } from '@services/config';

import { Product } from '@app-types/product';

export const productsSkeleton: Product[] = Array.from({ length: 9 }, (_, index) => {
    return {
        id: `bytel-${index}`,
        urlKey: `bytel-${index}`,
        gencode: 'bytel',
        url: 'https://wwww.bouyguestelecom.fr',
        brand: 'Bouygues',
        name: 'Telecom',
        priceFrom: true,
        price: 0,
        recommended: false,
        hasCashbackOffer: true,
        image: `${getAppConfig().assets.cmsImages.url}/wall/lazyload-phone-default-image.svg`,
        details: {
            price: {
                initial: 0,
                final: 0,
                forever: 0,
            },
            fundings: [],
            discounts: [],
        },
        colors: [],
        hasFunding: true,
        availableCnc: false,
        inStock: true,
        products: [],
        categories: [],
    };
});
