'use client';

import { Container, Icon, IconName, IconSize, TrilogyColor } from '@bytel/trilogy-react-ts';

export function HandsFree() {
    return (
        <Container fluid background={TrilogyColor.WHITE} data-cy='hands-free'>
            <Icon
                size={IconSize.MEDIUM}
                name={IconName.HANDSFREE}
                textClassName='has-text-weight-semibold'
                content='Kit mains libres recommandé'
            />
        </Container>
    );
}
